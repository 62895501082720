import logo from './logo.svg';
import './App.css';
import React, { useEffect, useRef } from 'react';
import { Application } from '@splinetool/runtime';
import NewWidget from './waitlist';


function App() {
  const canvasRef = useRef(null);

  useEffect(() => {
    // This checks if the canvasRef is currently being referenced
    if (canvasRef.current) {
      const app = new Application(canvasRef.current);
      app.load('https://prod.spline.design/YXIAv2rJjaTDP7BJ/scene.splinecode');

      // Optional: Clean up when the component unmounts
      return () => {
        app.dispose();
        canvasRef.current.removeEventListener('wheel', (e) => e.preventDefault(), { passive: false });
      };
    }
  }, []);

  return (
    <div>
      <canvas ref={canvasRef} style={{ width: '100%', height: '100%', objectFit: 'cover', zIndex: -1 }} />
      <div className="App">
        <div className="hero">
          <h1>Supercharge LLM Performance with Semantic Caching</h1>
          <p>Save up to 85% on inference costs, achieve State of the Art performance from smaller models and improve performance by up to 75% without LLM lock-in.</p>
        {/* <div>
          <button className="waitlist-button" onClick={() => window.open("https://getwaitlist.com/waitlist/19637", "_blank")}>Join Waitlist</button>
        </div> */}
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <NewWidget/>
        </div>
        </div>
        <footer className="footer">
          <p>© 2024 InferenceIndex</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
